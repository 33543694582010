<template>
  <div class="container">
    <div class="swiper">
      <swiper :imgList="imgList"></swiper>
    </div>
    <div class="title">亲子关系</div>
    <div class="content">
      <div class="topics">
        <div class="topics" v-for="(items, index) in topicList" :key="index">
          <div class="content_detail">
            <div class="content_desc">
              <div class="content_title" style="display: flex">
                <div
                  class="content_desc_title"
                  @click="inToDetail(items)"
                  v-for="(item, index) in items.tip"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>

              <h1 @click="inToDetail(items)">{{ items.title }}</h1>
              <span @click="inToDetail(items)">{{ items.desc }}</span>
              <div class="continue_read" @click="inToDetail(items)">
                继续阅读
              </div>
            </div>
            <div class="content_pic" @click="inToDetail(items)">
              <img :src="items.img" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="updateEssay">
        <div class="essayTitle">最新文章</div>
        <div class="news" v-for="(essay, index) in essayList" :key="index">
          <div class="new">{{ essay }}</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="50">
      </el-pagination>
    </div>
    <!-- 尾部 -->
    <div class="footer"></div>
  </div>
</template>
<script>
import swiper from "../components/swiper.vue";
export default {
  components: {
    swiper,
  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/01/61f8de98265ac.jpg",
          desc: "妈妈不得不说",
          detail_desc:
            "你是我的财产，我的孩子，我的事业；我要等到你得到幸福的时候，我才能取得我的幸福。",
          desc_btn: "继续阅读",
        },
      ],
      essayList: [
        "妈不得不说的话",
        "走出暴力阴影",
        "跨文化婚姻辅导",
        "蕾丝的联想",
        "什么是婚姻中的财务虐待",
      ],
      topicList: [
        {
          tip: ["亲子关系"], //标签
          title: "来自老爸的陪伴", //标题
          desc: "作父母的责任是提供子女安适的环境与空间，引导他们发展出自己独特的个性，教导孩子建立正确的人生观与信仰。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f7513123d74.jpg",
        },
        {
          tip: ["亲子关系"], //标签
          title: "不作缺席妈妈", //标题
          desc: "在孩子需要我们的时候，作母亲的你，缺席了吗？", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f751c775f83.jpg",
        },
        {
          tip: ["亲子关系"], //标签
          title: "嗨，我回来了！", //标题
          desc: "我们每个人都有不顺心的时候。只要有三位以上的成年人住在同一个屋檐下，不管彼此有没有亲戚关系，都是一项挑战。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f751f175f81.jpg",
        },
        {
          tip: ["亲子关系", "青年一代"], //标签
          title: "孩子，我爱你！", //标题
          desc: "处处让孩子在你的一举一动中感受到你对他无微不至的爱。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f752455e6e6.jpg",
        },
        {
          tip: ["亲子关系", "婚姻关系"], //标签
          title: "珍爱子女", //标题
          desc: "珍爱青少年子女的父母也会有类似的反应。当你珍爱一个人的时候，你会想办法保护他，尽全力帮助他成功，你会强调他的优点。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69a5acc603.jpg",
        },
        {
          tip: ["亲子关系", "热点话题"], //标签
          title: "空巢的陷阱", //标题
          desc: "我发现空巢期实际上开启了我们更多的机会，共同完成许多喜爱的工作。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f7536be5a9e.jpg",
        },
        {
          tip: ["亲子关系", "热点话题"], //标签
          title: "教孩子减压", //标题
          desc: "你的孩子对处理压力的确有一套，在身、心、灵各方面一点也不受伤害。继续保持这种健康的态度，就会活得更好。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f753c5a3f80.jpg",
        },
        {
          tip: ["亲子关系"], //标签
          title: "希望的故事", //标题
          desc: "曼德拉十岁的时候，希望仍存回到2009年，那个时候至少他的家里还有吃的，还有地方可住。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f753c56c8cb.jpg",
        },
        {
          tip: ["热点话题"], //标签
          title: "寻找最美的爱", //标题
          desc: "一位唐氏儿男孩的妈妈曾告诉我，与看起来残疾不完全的孩子生活在一起，并且爱他，可向我们展示完全由爱推动的智力进展。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/31/61f7549cdaa07.jpg",
        },
        {
          tip: ["亲子关系", "热点话题"], //标签
          title: "科技与品德", //标题
          desc: "你一定还记得从前没有数码相机、iPod、手机、高清电视、笔记本计算机的时候，日子是怎么过的。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69c580f320.jpg",
        },
      ],
    };
  },
  created() {},
  methods: {
    inToDetail(items) {
      console.log(items);
      this.$router.push({
        name: "detail",
        params: { items },
      });
    },
  },
};
</script>
<style scoped>
</style>
<style  lang='less' scoped>
.swiper {
  object-fit: fill;
  overflow: hidden;
  height: 500px;
}
.container {
  .title {
    padding-left: 160px;
    margin-top: 20px;
    height: 75px;
    text-align: left;
    line-height: 75px;
    background-color: #7ebec5;
    font-weight: 550;
    font-size: 30px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-left: 160px;
    background-color: #f5f6f7;
    .topics :nth-child(odd) {
      // height: 600px;
      color: #f1f4f5;
      font-size: 30px;
      font-weight: 550;
      // background-color: #f5f6f7;
      .content_detail {
        display: flex;
        margin: 0 auto;
        margin-top: 50px;
        width: 880px;
        height: 300px;
        background-color: #fff;
        .content_desc {
          width: 440px;
          height: 300px;
          box-sizing: border-box;
          padding: 50px;
          display: flex;
          flex-direction: column;
          background-color: #fff;

          h1 {
            margin-top: 20px;
            text-align: left;
            font-size: 26px;
            color: #3a3a3a;
            cursor: pointer;
          }
          span {
            margin-top: 20px;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            color: #666;
            cursor: pointer;
          }
          .continue_read {
            position: relative;
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #257d94;
            cursor: pointer;
          }
        }
        .content_desc_title {
          color: #fff;
          padding: 0 5px;
          // width: 105px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          background-color: #000;
          cursor: pointer;
        }
        .content_desc_title:nth-child(1) {
          margin-right: 10px;
        }
        .content_pic {
          width: 440px;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            // opacity: ;
            transition: all 0.3s ease;
          }
          img:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    .topics :nth-child(even) {
      color: #f1f4f5;
      font-size: 30px;
      font-weight: 550;
      // background-color: #f5f6f7;
      .content_detail {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 auto;
        margin-top: 50px;
        width: 880px;
        height: 300px;
        background-color: #fff;
        .content_desc {
          width: 440px;
          height: 300px;
          box-sizing: border-box;
          padding: 50px;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          h1 {
            margin-top: 20px;
            text-align: left;
            font-size: 26px;
            color: #3a3a3a;
            background-color: #fff;
            cursor: pointer;
          }
          span {
            margin-top: 20px;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            color: #666;
            background-color: #fff;
            cursor: pointer;
          }
          .continue_read {
            position: relative;
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #257d94;
            cursor: pointer;
            background-color: #fff;
            cursor: pointer;
          }
        }
        .content_desc_title {
          color: #fff;
          padding: 0 5px;
          // width: 105px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          background-color: #000;
          cursor: pointer;
        }
        .content_desc_title:nth-child(1) {
          margin-right: 10px;
        }
        .content_pic {
          width: 440px;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            // opacity: 0.7;
            transition: all 0.3s ease;
          }
          img:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    .updateEssay {
      margin: 50px 0 0 50px;
      width: 220px;
      height: 240px;
      font-size: 18px;
      font-weight: 550;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #dcddde;
      .essayTitle {
        text-align: left;
        margin-bottom: 5px;
      }
      .new {
        margin-bottom: 10px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #666;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    padding: 10vw 0vw;

    background-color: #f5f6f7;
  }
  .footer {
    height: 100px;
    background-color: #5499c7;
  }
  @media screen and (max-width: 1140px) {
    .content {
      width: 100vw;
      padding: 0 20vw 0 10vw !important;
      span {
        display: inline-block;
        width: 60vw !important;
      }
    }
    .title {
      padding-left: 10vw !important;
    }
    .topics {
      height: auto !important;
      .content_detail {
        flex-direction: column-reverse !important;
        margin-top: 30px;
        // padding-top: 50px;
        height: auto !important;
        width: 80vw !important;

        .content_pic {
          height: auto !important;
          width: 80vw !important;
          display: flex;
          justify-content: center !important;
          align-items: center !important;
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
        .content_desc {
          padding: 50px !important;
          width: 80vw !important;
          height: auto !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>